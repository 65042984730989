import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';

import Preloader from './components/Preloader';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import Home from "./components/Home/Home";
import Menu from './components/Menu/Menu';
import Reviews from './components/Reviews/Reviews';
import SignIn from './components/Account/SignIn';
import Contact from './components/Contact/Contact';
import Profile from './components/Account/Profile';
import SignUp from './components/Account/SignUp'
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [load, updateLoad] = useState(true);
  const [user, updateUser] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateLoad(false);
    }, 1400);

    return () => clearTimeout(timer);
  }, []);

  const handleUser = (user) => {
    updateUser(user);
  }

  return (
    <Router>
      <Preloader load={load} />
      <Navbar User={user} />
      <ScrollToTop />
      <div className="App" id={load ? 'no-scroll' : 'scroll'}>
          <Routes>
            <Route path='/' element={<Home User={user}/>} />
            <Route path='/menu' element={<Menu />} />
            <Route path='/reviews' element={<Reviews />} />
            <Route path='/contactus' element={<Contact />} />
            <Route path='/login' element={<SignIn />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/profile' element={<Profile />} />
            {/* <Route path='/' element={<Navigate to='/' />} /> */}
          </Routes>
      </div>
    </Router>
  );
}

export default App;
