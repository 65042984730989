import React, {useState} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav'
import logo from '../Assets/logo1.png';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { AiFillInstagram } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi'

function NavBar( { user } ) {
    const [navStick, updateNavbar] = useState(false);
    const [expand, updateExpanded] = useState(false);

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        }
        else{
            updateNavbar(false);
        }
    }
    window.addEventListener("scroll", scrollHandler);

    return (
        <Navbar
        expanded={expand}
        fixed="top"
        className={navStick ? "sticky" : "navbar"}
        expand='md'>
        <Container>
            <Navbar.Brand href="/" className='d-flex' style={{paddingTop: '.3em'}}>
                <img src={logo} className="img-fluid logo" alt="brand"/>
            </Navbar.Brand>
            <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={() => {
                updateExpanded(expand ? false : "expanded");
                }}>
                <span></span>
                <span></span>
                <span></span>
            </Navbar.Toggle>
            <Navbar.Collapse id='responsive-navbar-nav'>
                <Nav className="ms-auto" defaultActiveKey="#home">
                    <Nav.Item>
                        <Nav.Link as={Link} to='/' onClick={() => updateExpanded(false)}>
                            Home
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link href='https://ndfoodhub.com/' onClick={() => updateExpanded(false)}>
                            Order
                        </Nav.Link>
                    </Nav.Item>

                    {/* <Nav.Item>
                        <Nav.Link as={Link} to='/contactus' onClick={() => updateExpanded(false)}>
                            Contact Us
                        </Nav.Link>
                    </Nav.Item> */}

                    {/* {user ? 
                    <Nav.Item className='profile-btn'>
                        <Button
                            as={Link}
                            to='/profile'
                            onClick={() => updateExpanded(false)}
                            className="profile-btn-inner">
                            <FiUser style={{ fontSize: "1.3em" }} />
                        </Button>
                    </Nav.Item> : 
                    <Nav.Item >
                        <Nav.Link as={Link} to='/login' onClick={() => updateExpanded(false)}>
                            Sign In
                        </Nav.Link>
                    </Nav.Item> */}
                    {/* } */}

                    <Nav.Item className="insta-btn">
                        <Button
                            href="https://www.instagram.com/pearlsontap/"
                            target="_blank"
                            className="insta-btn-inner">
                            <AiFillInstagram style={{ fontSize: "1.3em" }} />
                        </Button>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
  )
}

export default NavBar;