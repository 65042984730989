import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Coins from '../../Assets/coins.png';
import KeoughMap from '../../Assets/keoughMap.png';
import Colab from '../../Assets/studentsColab.jpeg';

function Home() {
  return (
    <>
    <section>
      <Container fluid className="home-section" id="home">
        <Row md={2} l={2} xs={1} s={1}>
          <Col className="home-header" />

          <Col className='my-auto'style={{paddingTop: '70px'}}>
            <h1 style={{fontSize: '3.5em'}}>
              <b>Pearls On Tap</b>
            </h1>
          </Col>
        </Row>
      </Container>
    </section>
    <section>
      <Container fluid className='description' id='home'>
        <Row md={2} l={2} xs={1} s={1}>
          <Col className="boba-info my-auto">
            <h1 style={{fontSize:'2.6em'}}>
              The <b>premier</b> boba experience on<p>Notre Dame campus</p>
            </h1>
            <Button href='https://ndfoodhub.com/' style={{marginBottom: '1em'}}>
              Order Now
            </Button>
          </Col>
          <Col className='friend-pic' />
        </Row>
      </Container>
    </section>
    {/* <section className='selling-points' style={{marginTop: '1em'}}>
      <Container fluid className='selling-points'>
        <Row md={2} l={2} xs={1} s={1}>
          <Col className='my-auto four-group'>
            <div className='four-group-img'>
              <img src={KeoughMap} alt='Look in Assets' className='row1' />
            </div>
            <h5><b>On Campus Location</b></h5>
            <p className='four-group-description'></p>
          </Col>
          <Col className='my-auto four-group'>
            <div className='four-group-img'>
              <img src={Colab} alt='Look in Assets' className='row1'/>
            </div>
            <h5><b>Student Led</b></h5>
            <p className='four-group-description'></p>
          </Col>
        </Row>
        <Row md={2} l={2} xs={1} s={1}>
          <Col className='my-auto four-group'>
            <div className='four-group-img'>
              <img className='row2' alt='Look in Assets'/>
            </div>
            <h5><b>Boba for Everyone</b></h5>
            <p className='four-group-description'></p>
          </Col>
          <Col className='my-auto four-group'>
            <div className='four-group-img'>
              <img src={Coins} alt='Look in Assets' className='row2 coin'/>
            </div>
            <h5><b>Cheap Prices</b></h5>
            <p className='four-group-description'></p>
          </Col>
        </Row>

      </Container>
    </section> */}
    </>
  )
}

export default Home;